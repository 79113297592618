import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import Utils from '../../utils';
import $ from '../../styles/global';
import Details from './Details';
import Buttons from './Buttons';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout().padding3}px 0;
`;

const JobDetails = () => {
  const data = useSelector(({ jobData, userData }) => ({ jobData, userData }));
  const jobId = Utils.getSearchParam('id');
  const job = data.jobData.filter(({ id }) => id === jobId)[0] || {};

  return (
    <Section>
      <Container>
        <Details job={job} />
        <Buttons job={job} user={data.userData} />
      </Container>
    </Section>
  );
};

export default JobDetails;
