import React, { useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Utils from '../../utils';
import $ from '../../styles/global';
import TimeIcon from '../../assets/icons/time.svg';
import LabelIcon from '../../assets/icons/label.svg';
import MoneyIcon from '../../assets/icons/money.svg';
import LocationIcon from '../../assets/icons/location.svg';
import BriefcaseIcon from '../../assets/icons/briefcase.svg';
import DollarIcon from '../../assets/icons/dollar.svg';

const Container = styled.div`
  background-color: ${$.color.lightgray3};
  border-radius: 12px;
  margin: 0 auto;
  padding: ${$.layout().padding3}px;
  position: relative;

  & > *:last-child {
    margin-bottom: 0;
  }

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    width: calc(70% - ${$.layout().padding3 * 2}px);
  }
  // #endregion

  // NOTE: Tablet
  // #region
  @media ${$.device.tablet} {
    width: calc(85% - ${$.layout().padding3 * 2}px);
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: calc(100% - ${$.layout().padding3 * 2}px);
  }
  // #endregion
`;

const Title = styled.div`
  font-size: 30px;
  font-family: SF Pro Bold;
  margin-bottom: ${$.layout().margin5}px;
  width: 60%;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    font-size: 25px;
  }
  // #endregion
`;

const Subtitle = styled.div`
  font-size: 20px;
  font-family: SF Pro Bold;
  margin-bottom: ${$.layout().margin5}px;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    font-size: 18px;
  }
  // #endregion
`;

const Company = styled.a`
  display: inline-block;
  color: ${$.color.blue};
  font-size: 17px;
  margin-bottom: ${$.layout().margin5}px;
  width: 70%;

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
  }
  // #endregion
`;

const InfoContainer = styled.div`
  margin: ${$.layout().margin3}px 0;
`;

const Info = styled.div`
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  & > span {
    line-height: 1.3em;
    font-size: 15px;
    word-break: break-word;
    width: calc(100% - ${$.layout().margin5}px - 20px);
  }

  & > svg {
    width: 20px;
    height: 20px;
    position: relative;
    top: -2px;
    fill: ${$.color.black2};
    margin-right: ${$.layout().margin5}px;
    align-self: flex-start;
  }
`;

const TimestampContainer = styled.div`
  position: absolute;
  top: ${$.layout().padding3}px;
  right: ${$.layout().padding3}px;
  margin-bottom: ${$.layout().margin3}px;

  & > *:not(:last-child) {
    margin-bottom: ${$.layout().margin5}px;
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    position: relative;
    top: 0;
    right: 0;
  }
  // #endregion
`;

const LastCreated = styled.div`
  align-self: flex-end;
  display: flex;
  flex-direction: row;
  align-items: center;

  & > span {
    color: ${$.color.gray2};
    font-size: 14px;
  }

  & > svg {
    width: 18px;
    height: 18px;
    fill: ${$.color.gray2};
    margin-right: ${$.layout().margin5}px;
  }
`;

const Description = styled.div`
  margin-bottom: ${$.layout().margin3}px;

  p {
    margin: 0;
    margin-bottom: ${$.layout().margin4}px;
    line-height: 1.3em;
  }

  h2,
  h3 {
    margin: 0;
    margin-bottom: ${$.layout().margin4}px;
  }

  strong,
  b {
    font-family: SF Pro Bold;
  }

  i,
  em {
    font-family: SF Pro Italic;
  }

  strong {
    em {
      font-family: SF Pro BoldItalic;
    }
  }

  em {
    strong {
      font-family: SF Pro BoldItalic;
    }
  }

  ol,
  ul {
    padding-left: ${$.layout().padding3}px;
  }

  ${$.br}
`;

const Pills = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: ${$.layout().margin3}px;

  & > div {
    font-size: 15px;
    padding: 6px 10px;
    border-radius: 10px;
    background-color: ${$.color.lightgray1};
    margin-right: ${$.layout().margin5}px;
    margin-bottom: ${$.layout().margin5}px;
  }
`;

const Details = ({ job }) => {
  const { current } = useRef({ ...job });
  const parsedTimestamp = Utils.translateJobTimestampToString({
    createdAt: current.createdAt,
    updatedAt: current.updatedAt,
  });

  return (
    <Container>
      <TimestampContainer>
        <LastCreated>
          <TimeIcon />
          <span>{parsedTimestamp.createdAt}</span>
        </LastCreated>
        {parsedTimestamp.lastCreated !== parsedTimestamp.createdAt && (
          <LastCreated>
            <TimeIcon />
            <span>{parsedTimestamp.updatedAt}</span>
          </LastCreated>
        )}
      </TimestampContainer>
      <Title>{current.title}</Title>
      <Company
        href={current.company.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        {current.company.name}
      </Company>
      <InfoContainer>
        <Info>
          <LabelIcon />
          <span>
            {Utils.getEnumText({
              type: 'jobCategory',
              value: current.category,
            })}
          </span>
        </Info>
        <Info>
          <MoneyIcon />
          <span>
            {Utils.getEnumText({
              type: 'jobType',
              value: current.type,
            })}
          </span>
        </Info>
        <Info>
          <LocationIcon />
          <span>{`${current.location.city}, ${current.location.country}`}</span>
        </Info>
        <Info>
          <BriefcaseIcon />
          <span>
            {Utils.getEnumText({
              type: 'workExperience',
              value: current.workExperience,
            })}
          </span>
        </Info>
        <Info>
          <DollarIcon />
          <span>{`SGD ${current.salary[0]} - SGD ${current.salary[1]}`}</span>
        </Info>
      </InfoContainer>
      <Subtitle>Job Description</Subtitle>
      <Description dangerouslySetInnerHTML={{ __html: current.description }} />
      <Subtitle>Benefits</Subtitle>
      <Pills>
        {current.benefits.map((text, index) => (
          <div key={`benefit_pill_${index}`}>{text}</div>
        ))}
      </Pills>
      <Subtitle>Skills Required</Subtitle>
      <Pills>
        {current.skillRequirements.map((text, index) => (
          <div key={`skill_requirements_${index}`}>{text}</div>
        ))}
      </Pills>
    </Container>
  );
};

Details.propTypes = {
  job: PropTypes.shape({
    title: PropTypes.string,
    editingCompany: PropTypes.string,
    skillRequirements: PropTypes.arrayOf(PropTypes.string),
    benefits: PropTypes.arrayOf(PropTypes.string),
    salary: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    id: PropTypes.string,
    workExperience: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    company: PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    type: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
};

export default Details;
