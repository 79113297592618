import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';

import { getJobGQL } from '../apollo/queries';
import Utils from '../utils';
import { updateJob } from '../actions';
import JobDetails from '../components/JobDetails';
import Message from '../components/Message';
import Seo from '../components/seo';

const IndexPage = () => {
  const [updateOneJob] = Utils.useActions([updateJob]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [getOneJob] = useLazyQuery(getJobGQL, {
    onCompleted: (results) => {
      if (results?.job?.error) {
        setErrorMessage(true);
        return;
      }

      updateOneJob(results.job);
      setShowDetails(true);
    },
    onError: () => {
      setErrorMessage(true);
    },
  });

  useEffect(() => {
    const id = Utils.getSearchParam('id');

    if (id) {
      getOneJob({ variables: { id } });
    } else {
      setErrorMessage(true);
    }
  }, []);

  return (
    <>
      <Seo title="Job Details" />
      {errorMessage && !showDetails && (
        <Message message="An error occurred. Please make sure the job posting exists." />
      )}
      {!errorMessage && !showDetails && <Message message="Loading..." />}
      {!errorMessage && showDetails && <JobDetails />}
    </>
  );
};

export default IndexPage;
