import { gql } from 'apollo-boost';

const getJobGQL = gql`
  query getJob($id: String!) {
    job(id: $id) {
      ... on Job {
        id
        status
        title
        description
        location {
          city
          country
        }
        type
        workExperience
        category
        skillRequirements
        benefits
        createdAt
        editingCompany
        updatedAt
        salary
        company {
          name
          url
        }
      }
      ... on Failure {
        status
        error
      }
    }
  }
`;

export default getJobGQL;
