import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';

import Utils from '../../utils';
import { deleteJobGQL } from '../../apollo/mutations';
import { openModal, deleteJob } from '../../actions';
import $ from '../../styles/global';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: ${$.layout().margin3}px;

  & > *:not(:last-child) {
    margin-right: ${$.layout().margin3}px;
  }

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    & > *:not(:last-child) {
      margin-right: ${$.layout().margin4}px;
    }
  }
  // #endregion
`;

const UpdateButton = styled.button`
  background-color: ${$.color.blue};
  border-radius: 15px;
  border: none;
  padding: 10px 16px;
  color: ${$.color.white};
  font-size: 17px;
  opacity: 1;
  transition: opacity 0.25s ${$.easingFn.standard};

  // NOTE: Desktop
  // #region
  @media ${$.device.desktop} {
    &:hover {
      cursor: pointer;
      opacity: 0.65;
    }
  }
  // #endregion

  // NOTE: Mobile
  // #region
  @media ${$.device.mobile} {
    width: 100%;
    max-width: 130px;
    font-size: 15px;
  }
  // #endregion
`;

const DeleteButton = styled(UpdateButton)`
  background-color: ${$.color.red};
`;

const Buttons = ({ job, user }) => {
  const { current } = useRef({ ...job });
  const [showButtons, setShowButtons] = useState(true);
  const [deleteOneJob, openDeleteModal] = Utils.useActions([
    deleteJob,
    openModal,
  ]);
  const [deleteJobPosting] = useMutation(deleteJobGQL, {
    onCompleted: (results) => {
      if (results === null) {
        toast.error('An error occurred. Failed to delete job posting.');
      } else {
        deleteOneJob(results.deleteJob.id);
        toast.success('Job posting successfully deleted.');
        setTimeout(() => {
          navigate('/');
        }, 500);
      }
    },
    onError: () => {
      toast.error('An error occurred. Failed to delete job posting.');
    },
  });

  useEffect(() => {
    if (user.groups.indexOf('admin') > -1) {
      setShowButtons(true);
      return;
    }

    if (user.groups.indexOf('hr') > -1) {
      setShowButtons(false);
      return;
    }

    if (user.groups.indexOf('client') > -1) {
      setShowButtons(user.editingCompany.indexOf(current.editingCompany) > -1);
      return;
    }

    setShowButtons(false);
  }, [user]);

  return showButtons ? (
    <Container>
      <UpdateButton
        onClick={() => {
          navigate(`/update/?id=${current.id}`);
        }}
      >
        Make Changes
      </UpdateButton>
      <DeleteButton
        onClick={() => {
          openDeleteModal({
            title: 'Delete job posting',
            message: `You are about to delete job posting for the position ${current.title} by ${current.company.name}. This action is irreversible. Would you like to proceed?`,
            options: [
              {
                text: 'Cancel',
                color: $.color.white,
                callback: () => {},
              },
              {
                text: 'Yes',
                color: $.color.green,
                callback: () => {
                  deleteJobPosting({
                    variables: { id: current.id },
                  });
                },
              },
            ],
          });
        }}
      >
        Delete Job
      </DeleteButton>
    </Container>
  ) : (
    ''
  );
};

Buttons.propTypes = {
  user: PropTypes.shape({
    groups: PropTypes.arrayOf(PropTypes.string),
    editingCompany: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  job: PropTypes.shape({
    title: PropTypes.string,
    editingCompany: PropTypes.string,
    skillRequirements: PropTypes.arrayOf(PropTypes.string),
    benefits: PropTypes.arrayOf(PropTypes.string),
    salary: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
    id: PropTypes.string,
    workExperience: PropTypes.string,
    location: PropTypes.shape({
      city: PropTypes.string,
      country: PropTypes.string,
    }),
    company: PropTypes.shape({
      url: PropTypes.string,
      name: PropTypes.string,
    }),
    createdAt: PropTypes.string,
    updatedAt: PropTypes.string,
    type: PropTypes.string,
    category: PropTypes.string,
  }).isRequired,
};

export default Buttons;
