import { gql } from 'apollo-boost';

const getAllJobGQL = gql`
  query getAllJob {
    allJob(sort: { field: TITLE, order: ASC }) {
      id
      status
      title
      description
      location {
        city
        country
      }
      type
      workExperience
      category
      skillRequirements
      benefits
      createdAt
      updatedAt
      salary
      company {
        name
        url
      }
      editingCompany
    }
  }
`;

export default getAllJobGQL;
