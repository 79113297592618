import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import $ from '../styles/global';

const Section = styled.section``;

const Container = styled.div`
  padding: ${$.layout().padding3}px 0;
  text-align: center;
  font-family: 20px;
`;

const Message = ({ message }) => (
  <Section>
    <Container>{message}</Container>
  </Section>
);

Message.defaultProps = {
  message: '',
};

Message.propTypes = {
  message: PropTypes.string,
};

export default Message;
